<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="英文名称" prop="englist">
        <el-input v-model="info.englist" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="分类" prop="menu_ids">
        <el-select v-model="info.pid" placeholder="请选择分类" style="width: 100%">
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="pic">
        <SelectImgs :selectNum="1" :selectData="[info.pic]" :selectFunc="imgChoose" :idx="1"></SelectImgs>
        <span>702*268px</span>
      </el-form-item>
      <el-form-item label="背景图" prop="bg">
        <SelectImgs :selectNum="1" :selectData="[info.bg]" :selectFunc="imgChoose" :idx="2"></SelectImgs>
        <span>702*268px</span>
      </el-form-item>
      <el-form-item label="标准分">
        <el-input v-model="info.standard_score" placeholder="请输入标准分"></el-input>
      </el-form-item>
      <el-form-item label="参与人数">
        <el-input v-model="info.people" placeholder="请输入人数"></el-input>
      </el-form-item>
      <el-form-item label="题目选项">
        <el-button type="primary" @click="addItem">添加选项</el-button>
        <el-input v-model="info.options[index]" placeholder="请输入内容" v-for="(item,index) in info.options" :key="index" class="mg-tp-10">
          <el-button slot="append" icon="el-icon-delete" @click="delItem(index)">删除</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="宣传标语">
        <wang-edit :txt="info.ad_remark" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import WangEdit from "@/components/WangEdit";
import SelectImgs from '../../components/SelectImgs'
export default {
  data() {
    return {
      tableHeader: [],
      tableData: [],
      menuList: [],
      imgs: [],
      tableRandKey: 1,
      info: {
        id: 0,
        pid: [],
        pic: '',
        bg: '',
        title: '',
        englist: '',
        score: '',
        standard_score: '',
        people: '',
        ad_remark: '',
        options: []
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        pid: [
          {required: true, message: '请选择分类', trigger: 'blur'}
        ]
      },
      showInput: false,
    };
  },
  created() {
    this.getMenuList()
    if (this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  components: {
    SelectImgs,
    WangEdit
  },
  computed: {},
  methods: {
    showMenu() {
      this.showInput = true
    },
    setText(txt) {
      this.info.ad_remark = txt
    },
    addItem() {
      this.info.options.push("")
    },
    delItem(index) {
      this.info.options.splice(index,1)
    },
    imgChoose(item,idx) {
      if(idx==1) {
        this.info.pic = item.pic
      } else {
        this.info.bg = item.pic
      }
    },
    getMenuList() {
      this.$api.test.menuIndex({}, res => {
        if (res.errcode == 0) {
          this.menuList = res.data
        }
      })
    },
    getInfo() {
      var that = this
      this.$api.test.testDetail({id: that.info.id}, function (res) {
        if (res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = this.info
          this.$api.test.testEdit(param, function (res) {
            if (res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
</style>
